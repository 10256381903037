// Modernizer and Polyfills
require("./head/modernizr.js");
require("./head/polyfills.js");

// Splide
import Splide from "@splidejs/splide";

(function (global) {
    var simplefocus = {
        // Initialize everything
        init: function () {
            this.animateOnEnter();
            this.hamburger();
            this.menuDropdowns();
            this.youtubeEmbed();
            this.gallerySlider();
        },

        // Animate elements when they enter the viewport
        animateOnEnter() {
            const targets = document.querySelectorAll(".animated");

            function handleIntersection(entries) {
                entries.map((entry) => {
                    if (entry.isIntersecting) {
                        // do something
                    } else {
                        // do something else
                    }
                });
            }

            const options = {
                threshold: 0.9,
            };

            const observer = new IntersectionObserver(
                handleIntersection,
                options
            );
            targets.forEach(function (target) {
                observer.observe(target);
            });
        },

        // Hamburger menu toggle
        hamburger() {
            var hamburger = document.getElementById("hamburgerIcon");
            var menu = document.getElementById("mainNav");

            hamburger.addEventListener("click", function () {
                menu.classList.toggle("hidden");
                hamburger.classList.toggle("is-active");
            });
        },

        // Dropdowns for mobile and desktop
        menuDropdowns() {
            const mediaQuery = window.matchMedia("(min-width: 768px)");

            var menuDropdownToggles = document.querySelectorAll(
                ".menuDropdownToggle"
            );

            function disableAllSubnavs() {
                menuDropdownToggles.forEach(function (toggle) {
                    disableSubnav(toggle);
                });
            }

            function enableSubnav(el) {
                el.classList.add("subMenuActive");
                var currentSubNav = el.parentNode.querySelector(".subNav");
                currentSubNav.classList.remove("hidden");
            }

            function disableSubnav(el) {
                el.classList.remove("subMenuActive");
                var currentSubNav = el.parentNode.querySelector(".subNav");
                currentSubNav.classList.add("hidden");
            }

            function toggleSubnav(el) {
                el.classList.toggle("subMenuActive");
                var currentSubNav = el.parentNode.querySelector(".subNav");
                currentSubNav.classList.toggle("hidden");
            }

            if (mediaQuery.matches) {
                // Desktop
                document.addEventListener("click", function (e) {
                    var el = e.target;

                    if (el.classList.contains("menuDropdownToggle")) {
                        if (el.classList.contains("subMenuActive")) {
                            disableSubnav(el);
                        } else {
                            disableAllSubnavs();
                            enableSubnav(el);
                        }
                    } else {
                        disableAllSubnavs();
                    }
                });
            } else {
                // Mobile
                menuDropdownToggles.forEach(function (toggle) {
                    toggle.addEventListener("click", function () {
                        toggleSubnav(toggle);
                    });
                });
            }
        },

        // Convert empty youtubeVideo div to customized iFrame
        youtubeEmbed() {
            (function () {
                // Find all instances of .youtube
                var youtube = document.querySelectorAll(".youtubeVideo");

                for (var i = 0; i < youtube.length; i++) {
                    // Get the poster URL from the poster data attribute
                    var source = youtube[i].dataset.poster;

                    // Create the poster image
                    var image = new Image();
                    image.src = source;
                    image.alt = "video poster";
                    image.addEventListener(
                        "load",
                        (function () {
                            youtube[i].appendChild(image);
                        })(i)
                    );

                    // On click...
                    youtube[i].addEventListener("click", function () {
                        var iframe = document.createElement("iframe");

                        // ...load up an iframe...
                        iframe.setAttribute(
                            "class",
                            "absolute top-0 left-0 w-full h-full"
                        );
                        iframe.setAttribute("frameborder", "0");
                        iframe.setAttribute("allowfullscreen", "");
                        iframe.setAttribute(
                            "src",
                            "https://www.youtube.com/embed/" +
                                this.dataset.id +
                                "?showinfo=0&autoplay=1&vq=hd720"
                        );

                        // ..then add the iframe to the DOM
                        this.appendChild(iframe);
                    });
                }
            })();
        },

        // Gallery image slider on blog posts
        gallerySlider() {
            var sliderExists = document.getElementById("gallerySlider");

            if (sliderExists) {
                var gallerySliderController = new Splide(
                    "#gallerySliderController",
                    {
                        isNavigation: true,
                        fixedWidth: 100,
                        rewind: true,
                        fixedWidth: 100,
                        height: 80,
                        cover: true,
                        gap: 10,
                        pagination: false,
                        arrows: false,
                        focus: "center",
                    }
                ).mount();

                var gallerySlider = new Splide("#gallerySlider", {
                    type: "fade",
                    heightRatio: 0.667,
                    pagination: false,
                    arrows: true,
                    cover: true,
                });

                gallerySlider.sync(gallerySliderController).mount();
            }
        },
    };

    // Add window object
    global.simplefocus = simplefocus;

    // Run it
    simplefocus.init();
})(window);
